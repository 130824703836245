import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import Button from "../../../components/Button2";
import TextField from "../../../components/TextField";
import PageSection from "../../../components/PageSection";
import { useNotifications } from "../../../components/Notification";
import { Product } from "../../../types";

type ProductsFormProps = {
  products: Product[];
};

export default function ProductsForm(props: ProductsFormProps) {
  const { showNotification } = useNotifications();
  const { id: templateId } = useParams<{ id: string }>();
  const [products, setProducts] = useState<Product[]>(props.products);

  const [saveTemplateProducts] = useMutation(SAVE_TEMPLATE_PRODUCTS_MUTATION);
  const [removeTemplateProduct] = useMutation(REMOVE_TEMPLATE_PRODUCT_MUTATION);

  function setProductField<T>(
    fieldName: keyof Product,
    index: number,
    value: T
  ) {
    setProducts((productsToUpdate) => {
      const changeable = [...productsToUpdate];
      const toBeChanged = productsToUpdate[index];
      let newValue: any = {
        ...toBeChanged,
        [fieldName]: value,
      };

      if (fieldName === "overrideName") {
        newValue.name = value;
      }
      changeable[index] = newValue;
      return changeable;
    });
  }

  function addNewProduct() {
    setProducts((currentProducts: Product[]) => [
      ...currentProducts,
      ({
        id: "",
        name: "",
        overrideName: "",
        minimumDeliveryDays: 0,
        minimumDeliveryDaysOkinawa: 0,
        minimumDeliveryDaysHokkaido: 0,
        stock: 0,
        sellingPrice: 0,
        templateId,
        template: null, // Set template to null if not provided
      } as unknown) as Product, // Convert to 'unknown' first, then to 'Product'
    ]);
  }

  function deleteProduct(index: number) {
    const productToDelete = products[index];
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );

    if (isConfirmed) {
      if (productToDelete.id) {
        removeTemplateProduct({
          variables: {
            input: {
              id: productToDelete.id,
            },
          },
        })
          .then(() => {
            showNotification({
              message: `Product deleted successfully`,
              severity: "success",
            });
            // Remove product from local state after deletion
            setProducts((currentProducts: any) =>
              currentProducts.filter((_: any, i: number) => i !== index)
            );
          })
          .catch((err: any) => {
            if (err.message.includes("violates foreign key constraint")) {
              showNotification({
                message:
                  "Cannot delete product as it is referenced in existing orders.",
                severity: "error",
              });
            } else {
              showNotification({
                message: err.message,
                severity: "error",
              });
            }
          });
      } else {
        setProducts((currentProducts: any) =>
          currentProducts.filter((_: any, i: number) => i !== index)
        );
      }
    }
  }

  function validateProductForm(products: Product[]): boolean {
    for (const product of products) {
      if (!product.name) {
        return false;
      }
    }
    return true;
  }

  function saveTemplateProductsHandler() {
    if (!validateProductForm(products)) {
      showNotification({
        message: "Please fill in all required fields correctly.",
        severity: "error",
      });
      return;
    }

    saveTemplateProducts({
      variables: {
        input: {
          products: products,
        },
      },
    })
      .then(() => {
        showNotification({
          message: `Products saved successfully`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: err.message,
          severity: "error",
        });
      });
  }
  return (
    <Grid container spacing={2}>
      <PageSection lg={12}>
        <Button variant="contained" onClick={saveTemplateProductsHandler}>
          Save
        </Button>
        <Button variant="contained" sx={{ ml: 2 }} onClick={addNewProduct}>
          ADD PRODUCT
        </Button>
      </PageSection>
      {products.length > 0 ? (
        products.map((p, index) => (
          <PageSection key={p.id || index} lg={6}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <TextField
                  required
                  id={`${index}_name`}
                  label="Name"
                  value={p.overrideName ?? p.name}
                  onChange={(e: any) => {
                    setProductField("overrideName", index, e.target.value);
                  }}
                />
                <TextField
                  required
                  type="number"
                  id={`${index}_minimumDeliveryDays`}
                  label="Minimum Delivery Days"
                  value={p.minimumDeliveryDays}
                  onChange={(e: any) => {
                    if (e.target.value >= 0) {
                      setProductField(
                        "minimumDeliveryDays",
                        index,
                        Number(e.target.value)
                      );
                    }
                  }}
                />
                <TextField
                  required
                  type="number"
                  id={`${index}_minimumDeliveryDaysOkinawa`}
                  label="Minimum Delivery Days - Okinawa"
                  value={p.minimumDeliveryDaysOkinawa}
                  onChange={(e: any) => {
                    if (e.target.value >= 0) {
                      setProductField(
                        "minimumDeliveryDaysOkinawa",
                        index,
                        Number(e.target.value)
                      );
                    }
                  }}
                />
                <TextField
                  required
                  type="number"
                  id={`${index}_minimumDeliveryDaysHokkaido`}
                  label="Minimum Delivery Days - Hokkaido"
                  value={p.minimumDeliveryDaysHokkaido}
                  onChange={(e: any) => {
                    if (e.target.value >= 0) {
                      setProductField(
                        "minimumDeliveryDaysHokkaido",
                        index,
                        Number(e.target.value)
                      );
                    }
                  }}
                />
                <TextField
                  required
                  type="number"
                  label="Stock"
                  value={p.stock}
                  onChange={(e: any) => {
                    if (e.target.value >= 0) {
                      setProductField("stock", index, Number(e.target.value));
                    }
                  }}
                />
                <TextField
                  required
                  type="number"
                  startAdornment={"¥"}
                  label={"Selling Price"}
                  value={p.sellingPrice}
                  onChange={(e: any) => {
                    if (e.target.value >= 0) {
                      setProductField(
                        "sellingPrice",
                        index,
                        Number(e.target.value)
                      );
                    }
                  }}
                />
                <Button
                  onClick={() => deleteProduct(index)}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </PageSection>
        ))
      ) : (
        <PageSection lg={12}>
          <div>This template has no associated products</div>
        </PageSection>
      )}
    </Grid>
  );
}

const SAVE_TEMPLATE_PRODUCTS_MUTATION = gql`
  mutation SaveTemplateProductsMutation($input: SaveTemplateProductsInput!) {
    saveTemplateProducts(input: $input) {
      success
    }
  }
`;

const REMOVE_TEMPLATE_PRODUCT_MUTATION = gql`
  mutation RemoveTemplateProductMutation($input: RemoveTemplateProductInput!) {
    removeTemplateProduct(input: $input) {
      success
    }
  }
`;
