import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import queryString from "query-string";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import DataTable from "../../../components/DataTable";
import Select from "../../../components/Select";
import Button from "../../../components/Button2";
import { Location, LocationType } from "../../../types";
import DeleteLocationButton from "./DeleteLocationButton";
import { withStyles } from "@mui/styles";
import { Switch } from "@mui/material";
import { useNotifications } from "../../../components/Notification";

export default function Locationslist() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotifications();

  const pageSize = 12;
  const [page, setPage] = useState(defaultPage);
  const [search, setSearch] = useState(defaultSearch);
  const [type, setType] = useState(defaultType);
  const [displayFooterLocation] = useMutation(DISPLAY_FOOTER);
  useEffect(updateUrl, [page, search, type, navigate]);

  const { loading, error, data, fetchMore } = useQuery(GET_LOCATIONS, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      type: Object.values<string>(LocationType).includes(type) ? type : null,
      search: search,
    },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEdit },
    { name: "ID", field: "id" },
    { name: "Type", field: "type" },
    { name: "Prefecture", render: renderPrefectureName },
    { name: "City", render: renderCityName },
    { name: "Area", render: renderAreaName },
    { name: "Footer Display", render: renderFooterDisplay },
    { name: "", render: renderActionButtons },
  ];
  const rows = data ? data.locations.records : null;

  return (
    <DataTable
      loading={loading}
      columns={columns}
      rows={rows}
      topBarExtras={renderTopBarExtras()}
      onSearch={(event) => {
        setSearch(event.target.value);
        setPage(0);
      }}
      searchValue={search}
      pagination={{
        totalRows: data ? data.locations.totalRecords : null,
        pageSize: pageSize,
        currentPage: page,
        onChangePage: (_, newPage) => {
          setPage(newPage);
          fetchMore({
            variables: {
              offset: newPage * pageSize,
              limit: pageSize,
            },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        },
      }}
    />
  );

  function getParsedQueryString() {
    return queryString.parse(location.search);
  }

  function defaultPage() {
    const parsedQueryString = getParsedQueryString();
    return parsedQueryString.page
      ? parseInt(parsedQueryString.page.toString()) - 1
      : 0;
  }

  function defaultSearch() {
    const parsedQueryString = getParsedQueryString();
    return parsedQueryString.search
      ? parsedQueryString.search.toString()
      : null;
  }

  function defaultType() {
    const parsedQueryString = getParsedQueryString();
    return parsedQueryString.type ? parsedQueryString.type.toString() : "all";
  }

  function updateUrl() {
    const urlParams = { page: page + 1, search: search, type: type };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function renderEdit(location: Location) {
    return (
      <Button size="small" component={Link} to={`/locations/${location.id}`}>
        <EditIcon />
      </Button>
    );
  }

  function renderPrefectureName(location: Location) {
    let locationName = "";
    if (location.type === LocationType.prefecture)
      locationName = `${location.nameEn} (${location.nameJa})`;
    if (location.type === LocationType.city)
      locationName = `${location.parent.nameEn} (${location.parent.nameJa})`;
    if (location.type === LocationType.area)
      locationName = `${location.parent.parent.nameEn} (${location.parent.parent.nameJa})`;

    return (
      <span
        style={{
          fontWeight:
            location.type === LocationType.prefecture ? "bold" : "normal",
        }}
      >
        {locationName}
      </span>
    );
  }

  function renderCityName(location: Location) {
    if (location.type === LocationType.prefecture) return <></>;

    let locationName = "";
    if (location.type === LocationType.city)
      locationName = `${location.nameEn} (${location.nameJa})`;
    if (location.type === LocationType.area)
      locationName = `${location.parent.nameEn} (${location.parent.nameJa})`;

    return (
      <span
        style={{
          fontWeight: location.type === LocationType.city ? "bold" : "normal",
        }}
      >
        {locationName}
      </span>
    );
  }

  function renderAreaName(location: Location) {
    if (location.type !== LocationType.area) return <></>;

    return (
      <span
        style={{
          fontWeight: location.type === LocationType.area ? "bold" : "normal",
        }}
      >
        {location.nameEn} ({location.nameJa})
      </span>
    );
  }

  function renderActionButtons(location: Location) {
    return (
      <>
        <DeleteLocationButton location={location} />
      </>
    );
  }

  function renderFooterDisplay(location: Location) {
    const handleFooterDisplayWithId = (footerDisplay: boolean) =>
      handleFooterDisplay(location.id, footerDisplay);

    return (
      <ShowSwitch
        checked={location.footerDisplay}
        onChange={(event: any) =>
          handleFooterDisplayWithId(event.target.checked)
        }
      />
    );
  }

  function handleFooterDisplay(locationId: string, footerDisplay: boolean) {
    displayFooterLocation({
      variables: { locationId, footerDisplay },
      refetchQueries: ["LocationsQuery"],
    }).then(() =>
      showNotification({ message: "Updated", severity: "success" })
    );
  }

  function renderTopBarExtras() {
    return (
      <>
        <Select
          style={{ width: "150px", marginRight: "15px", marginTop: "4px" }}
          size="small"
          label="type"
          options={[
            { id: "all", name: "all" },
            { id: "prefecture", name: "prefecture" },
            { id: "city", name: "city" },
            { id: "area", name: "area" },
          ]}
          value={type}
          onChange={(value) => {
            setPage(0);
            setType(value);
          }}
        />
        <Button
          style={{ backgroundColor: "white", color: "#c8a063" }}
          variant="contained"
          component={Link}
          to="/locations/new"
          startIcon={<AddIcon />}
        >
          New
        </Button>
      </>
    );
  }
}

const ShowSwitch = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: { backgroundColor: "red" },
})(Switch);

const DISPLAY_FOOTER = gql`
  mutation DisplayFooterLocationMutation(
    $locationId: ID!
    $footerDisplay: Boolean!
  ) {
    displayFooterLocation(
      input: { locationId: $locationId, footerDisplay: $footerDisplay }
    ) {
      success
    }
  }
`;

const GET_LOCATIONS = gql`
  query LocationsQuery(
    $offset: Int
    $limit: Int
    $type: LocationType
    $search: String
  ) {
    locations(offset: $offset, limit: $limit, type: $type, search: $search) {
      totalRecords
      records {
        id
        type
        nameEn
        nameJa
        footerDisplay

        parent {
          type
          nameEn
          nameJa

          parent {
            type
            nameEn
            nameJa
          }
        }
      }
    }
  }
`;
