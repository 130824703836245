import React, { useState } from "react";
import { Dialog, DialogActions, Button, DialogContent } from "@mui/material";
import TextField from "../../../components/TextField";
import { useNotifications } from "../../../components/Notification";
import { gql, useMutation } from "@apollo/client";

interface EmailPreviewModalProps {
  open: boolean;
  onClose: () => void;
  listCompanyIds: string[] | null;
  listVenueIds: string[] | null;
  month: string | null;
}

const EmailPreviewModal: React.FC<EmailPreviewModalProps> = ({
  open,
  onClose,
  listCompanyIds,
  listVenueIds,
  month,
}: EmailPreviewModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sendEmailValidation] = useMutation(SEND_EMAIL_VALIDATION);
  const { showNotification } = useNotifications();
  const [email, setEmail] = useState<string>("");

  async function handleSendEmail() {
    setLoading(true);
    await sendEmailValidation({
      variables: {
        input: {
          email,
          companyIds: listCompanyIds,
          month: month,
          venueIds: listVenueIds,
        },
      },
    })
      .then((data: any) => {
        if (!data.data.sendEmailValidation.success) {
          showNotification({
            message: data.data.sendEmailValidation.message,
            severity: "error",
          });
        } else {
          onClose();
          setEmail("");
          showNotification({
            message: "Send email successfully",
            severity: "success",
          });
        }
      })
      .catch((err: Error) => {
        console.log(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={() => {
        onClose();
        setEmail("");
      }}
    >
      <DialogContent style={{ width: 800 }}>
        <h2 style={{ textAlign: "center", margin: "0px 0px 16px 0px" }}>
          Send email test
        </h2>
        <TextField
          label={"To email"}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSendEmail} color="primary">
          {loading ? "inprogress" : "Send email"}
        </Button>
        <Button
          onClick={() => {
            onClose();
            setEmail("");
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailPreviewModal;

const SEND_EMAIL_VALIDATION = gql`
  mutation SendEmailValidationMutation($input: SendEmailValidationInput!) {
    sendEmailValidation(input: $input) {
      success
      message
    }
  }
`;
