import { gql, useQuery } from "@apollo/client";
import { MenuItem } from "@mui/material";
import TextField from "../TextField";
import { Venue } from "../../types";
import convertSpaceFullWidthToHalfWidth from "../../hooks/useConvertSpaceFullwidthToHalfwidth";

type VenuesSelectionProps = {
  venues?: Venue[];
  venueId?: string;
  required?: boolean;
  disabled?: boolean;
  onVenueSelected: (venue: VenuesRecord | undefined) => void;
};

type VenuesRecord = {
  id: string;
  name: string;
  nameFurigana: string;
};

export default function VenuesSelection(props: VenuesSelectionProps) {
  const { data } = useQuery<{
    venues: { records: VenuesRecord[] };
  }>(GET_VENUES);

  const foundVenues =
    data && data.venues && data.venues.records ? data.venues.records : [];

  if (foundVenues.length === 0) {
    return (
      <TextField select label="Venue" value="0">
        <MenuItem value="0">Loading venues</MenuItem>
      </TextField>
    );
  }

  return (
    <TextField
      select
      required={props.required}
      disabled={props.disabled}
      label="Venue"
      value={props.venueId}
      onChange={(e: any) => {
        props.onVenueSelected(foundVenues.find((v) => v.id === e.target.value));
      }}
    >
      {foundVenues.map((venue) => (
        <MenuItem key={venue.id} value={venue.id}>
          {venue.name}{" "}
          {venue.nameFurigana &&
            `(${convertSpaceFullWidthToHalfWidth(venue.nameFurigana)})`}
        </MenuItem>
      ))}
    </TextField>
  );
}

const GET_VENUES = gql`
  query VenuesQuery {
    venues {
      total
      records {
        id
        name
        nameFurigana
      }
    }
  }
`;
