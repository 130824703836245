import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState, useMemo } from "react";
import Button from "../../../../../components/Button2";
import DataTable from "../../../../../components/DataTable";

export type ActivityEditHistory = {
  activityId: string;
  fieldName: string;
  newValue: string;
  oldValue: string;
  editStatus: string;
  createdAt: string;
};

type PriceTypeEditHistoriesProps = {
  editHistories: ActivityEditHistory[];
};

const PriceTypeEditHistories = ({
  editHistories,
}: PriceTypeEditHistoriesProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <div
      style={{ display: "flex", margin: "16px", justifyContent: "flex-end" }}
    >
      <Button color="secondary" onClick={() => setDialogOpen(true)}>
        Edit histories
      </Button>
      <EditHistoriesDialog
        open={dialogOpen}
        data={editHistories}
        closeDialog={() => setDialogOpen(false)}
      />
    </div>
  );
};

function EditHistoriesDialog(props: {
  open: boolean;
  data: ActivityEditHistory[];
  closeDialog: () => void;
}) {
  const columns = [
    { name: "Action", field: "editStatus" },
    { name: "Date", field: "createdAt" },
    { name: "New Value", render: renderNewValueData },
    { name: "Old Value", render: renderOldValueData },
  ];

  const [page, setPage] = useState(0);
  const pageSize = 10;

  const rows = useMemo(() => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    return props.data.slice(startIndex, endIndex);
  }, [page]);

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.closeDialog}>
      <DialogTitle>
        <h3 style={{ textAlign: "center" }}>Edit histories</h3>
      </DialogTitle>
      <DialogContent style={{ width: 1100 }}>
        <DataTable
          columns={columns}
          rows={rows}
          disableToolbar
          pagination={{
            totalRows: props.data.length ?? null,
            pageSize: pageSize,
            currentPage: page,
            onChangePage: (_, newPage) => {
              setPage(newPage);
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            props.closeDialog();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function renderNewValueData(data: ActivityEditHistory) {
  return <pre>{data.newValue}</pre>;
}

function renderOldValueData(data: ActivityEditHistory) {
  return <pre>{data.oldValue}</pre>;
}

export default PriceTypeEditHistories;
