import _ from "lodash";
import { UnitType, DataEditItem, EditStatus } from "../../../../../types";
import { Activity } from "../ActivityForm/EditActivity";

export function detectChanges(
  currentActivity: Activity,
  originalActivity?: Activity
) {
  const dataEdits: DataEditItem[] = [];

  currentActivity.id &&
    originalActivity &&
    originalActivity.priceTypes &&
    originalActivity.priceTypes.forEach((originalPriceType) => {
      const currentPriceType = currentActivity.priceTypes?.find(
        (item) => item.id === originalPriceType.id
      );

      const oldValue = preparePriceTypeItem(originalPriceType);
      const newValue = preparePriceTypeItem(currentPriceType);

      if (!currentPriceType) {
        dataEdits.push({
          fieldName: "PriceType",
          oldValue: JSON.stringify(oldValue),
          newValue: JSON.stringify({}),
          editStatus: EditStatus.REMOVED,
        });
      } else if (!_.isEqual(newValue, oldValue)) {
        dataEdits.push({
          fieldName: "PriceType",
          oldValue: JSON.stringify(oldValue),
          newValue: JSON.stringify(newValue),
          editStatus: EditStatus.TOUCHED,
        });
      }
    });

  currentActivity.priceTypes?.forEach((priceType) => {
    const originalPriceType = originalActivity?.priceTypes?.find(
      (item) => item.id === priceType.id
    );

    if (!originalPriceType) {
      dataEdits.push({
        fieldName: "PriceType",
        oldValue: JSON.stringify({}),
        newValue: JSON.stringify(preparePriceTypeItem(priceType)),
        editStatus: EditStatus.ADDED,
      });
    }
  });

  return dataEdits;
}

function preparePriceTypeItem(priceType: any) {
  return {
    name: priceType?.name,
    unitType: convertUnitType(priceType?.unitType ?? ""),
    amount: priceType?.amount,
    contractedPrice: priceType?.contractedPrice,
    active: priceType?.active ?? false,
  };
}

function convertUnitType(unitType: string) {
  return unitType === "SET" ? UnitType.SET : UnitType.PERSON;
}
