import { useMemo } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import Button from "../../../../components/Button2";
import { format } from "date-fns";
import { downloadFile } from "../../../../helpers/download";

const BookingStatsExportButton = ({ date }: { date: Date }) => {
  const variables = useMemo(
    () => ({
      month: format(date, "yyyy-MM-dd"),
    }),
    [date]
  );

  const [getBookingApprovalStats, { loading }] = useLazyQuery<
    GetBookingApprovalStatsResponse,
    GetBookingApprovalStatsInput
  >(GET_BOOKING_APPROVAL_STATS, {
    fetchPolicy: "network-only",
    onCompleted: ({ bookingApprovalStats }) => {
      const headers = [
        "Venue name",
        "Plan name",
        "Plan ID",
        "CONFIRMED件数",
        "REJECTED件数",
        "CONFIRMED件数＆REJECTED件数の合計件数",
        "Reject rate",
        "承認率",
      ];

      const csvData: string[] = [];

      bookingApprovalStats.forEach((item) => {
        csvData.push(
          [
            `"${item.venueName}"`,
            `"${item.planName}"`,
            item.planId,
            item.confirmedTotal,
            item.rejectedTotal,
            item.total,
            item.rejectionRate,
            item.approvalRate,
          ].join(",")
        );
      });

      const fileName = `今月の予約承認率_${format(date, "yyyy_MM")}.csv`;

      downloadFile({
        data: [headers.join(","), ...csvData].join("\n"),
        fileName: fileName,
        fileType: "text/csv",
      });
    },
  });

  return (
    <Button
      disabled={loading}
      variant="contained"
      onClick={() => getBookingApprovalStats({ variables })}
    >
      download booking stats data
    </Button>
  );
};

type GetBookingApprovalStatsInput = { month: string };
type GetBookingApprovalStatsResponse = {
  bookingApprovalStats: {
    id: string;
    planId: string;
    planName: string;
    venueName: string;
    confirmedTotal: number;
    rejectedTotal: number;
    total: number;
    rejectionRate: string;
    approvalRate: string;
  }[];
};

const GET_BOOKING_APPROVAL_STATS = gql`
  query GetBookingApprovalStats($month: String!) {
    bookingApprovalStats(month: $month) {
      id
      planId
      planName
      venueName
      confirmedTotal
      rejectedTotal
      total
      rejectionRate
      approvalRate
    }
  }
`;

export default BookingStatsExportButton;
