import { Typography, Divider, Button, ButtonGroup } from "@mui/material";
import { useState } from "react";

const AllWeekdays: string[] = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const AllHolidays: string[] = ["祝日", "祝前日", "祝後日"];
const AllHours: number[][] = [
  [0, 1, 2, 3, 4, 5, 6, 7],
  [8, 9, 10, 11, 12, 13, 14, 15],
  [16, 17, 18, 19, 20, 21, 22, 23],
];
const AllMinutes: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

const prependZero = (n: number) => `${n}`.padStart(2, "0");

type AddedSchedule = {
  weekdays: string[];
  holidays?: string[];
  hours: number[];
  minutes: number[];
};

type NewRecurrenceRuleProps = {
  onAdded: (schedule: AddedSchedule) => void;
};

function NewRecurrenceRule(props: NewRecurrenceRuleProps) {
  const [weekdays, setWeekdays] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);
  const [hours, setHours] = useState<number[]>([]);
  const [minutes, setMinutes] = useState<number[]>([]);

  const someFieldsAreEmpty =
    (weekdays.length === 0 && holidays.length === 0) ||
    hours.length === 0 ||
    minutes.length === 0;
  const allFieldsAreEmpty =
    weekdays.length === 0 &&
    holidays.length === 0 &&
    hours.length === 0 &&
    minutes.length === 0;

  const reset = () => {
    setWeekdays([]);
    setHolidays([]);
    setHours([]);
    setMinutes([]);
  };

  return (
    <>
      <Typography>Weekdays:</Typography>

      <ButtonGroup size="small" color="primary">
        {AllWeekdays.map((w, idx) => (
          <Button
            disabled={holidays.length > 0}
            variant={weekdays.includes(w) ? "contained" : "outlined"}
            key={"w_" + idx}
            onClick={() => {
              setWeekdays((weekdays) =>
                weekdays.includes(w)
                  ? weekdays.filter((w2) => w2 !== w)
                  : weekdays.concat(w)
              );
            }}
          >
            {w}
          </Button>
        ))}
        {AllHolidays.map((h, idx) => (
          <Button
            disabled={weekdays.length > 0}
            variant={holidays.includes(h) ? "contained" : "outlined"}
            key={"h_" + idx}
            onClick={() =>
              setHolidays((holidays) =>
                holidays.includes(h)
                  ? holidays.filter((h2) => h2 !== h)
                  : holidays.concat(h)
              )
            }
          >
            {h}
          </Button>
        ))}
      </ButtonGroup>
      <Typography>Hours:</Typography>
      {AllHours.map((row, idx) => (
        <ButtonGroup size="small" color="primary" key={idx}>
          {row.map((h) => (
            <Button
              variant={hours.includes(h) ? "contained" : "outlined"}
              key={h}
              onClick={() => {
                setHours((hours) =>
                  hours.includes(h)
                    ? hours.filter((h2) => h2 !== h)
                    : hours.concat(h)
                );
              }}
            >
              {prependZero(h)}
            </Button>
          ))}
        </ButtonGroup>
      ))}
      <Typography>Minutes:</Typography>
      <ButtonGroup size="small" color="primary">
        {AllMinutes.map((m) => (
          <Button
            variant={minutes.includes(m) ? "contained" : "outlined"}
            key={m}
            onClick={() => {
              setMinutes((minutes) =>
                minutes.includes(m)
                  ? minutes.filter((m2) => m2 !== m)
                  : minutes.concat(m)
              );
            }}
          >
            {m}
          </Button>
        ))}
      </ButtonGroup>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <Button
        onClick={() => {
          setWeekdays(AllWeekdays);
          setHours([17, 18, 19, 20, 21]);
          setMinutes([0]);
        }}
      >
        Dinner Everyday
      </Button>
      <Button
        onClick={() => {
          setWeekdays(["SA", "SU"]);
          setHours([11, 12, 13, 14]);
          setMinutes([0]);
        }}
      >
        Lunch Weekends
      </Button>
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <Button
        disabled={someFieldsAreEmpty}
        onClick={() => {
          props.onAdded({
            weekdays,
            holidays,
            hours,
            minutes,
          });

          reset();
        }}
      >
        Add
      </Button>
      <Button
        disabled={allFieldsAreEmpty}
        onClick={() => {
          reset();
        }}
      >
        Reset
      </Button>
    </>
  );
}
export default NewRecurrenceRule;
