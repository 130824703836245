import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import convertSpaceFullWidthToHalfWidth from "../../../../hooks/useConvertSpaceFullwidthToHalfwidth";

import Notification, {
  NotificationData,
} from "../../../../components/Notification";
import { VenueReservationType } from "../types";

function ResendEmailsButton(props: ResendEmailsButtonProps) {
  const reservation = props.reservation;
  const [menuAnchor, setMenuAnchor] = useState<null | Element>(null);
  const [emailToResend, setEmailToResend] = useState<null | EmailType>(null);
  const [notification, setNotification] = useState<null | NotificationData>(
    null
  );
  const [resendVenueReservationEmail] = useMutation(
    RESEND_VENUE_RESERVATION_EMAIL
  );

  return (
    <>
      <Button
        variant="contained"
        startIcon={<SendIcon />}
        endIcon={<ArrowDropDownIcon />}
        onClick={(event: { currentTarget: Element }) => {
          setMenuAnchor(event.currentTarget);
        }}
      >
        Resend Emails
      </Button>
      <ResendEmailsMenu />
      <ResendDialog />
      {notification && (
        <Notification
          message={notification.message}
          severity={notification.severity}
          onClose={() => setNotification(null)}
        />
      )}
    </>
  );

  function ResendEmailsMenu() {
    return (
      <Menu
        className={"resend-emails-menu"}
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setMenuAnchor(null)}
      >
        {reservation.status === "NOT_YET_RESERVED" ? (
          <MenuItem
            onClick={() => {
              setEmailToResend("VENUE_RESERVATION_REQUEST");
              setMenuAnchor(null);
            }}
          >
            Resend {EmailNames["VENUE_RESERVATION_REQUEST"]}
          </MenuItem>
        ) : null}
        {reservation.status === "RESERVED" ? (
          <MenuItem
            onClick={() => {
              setEmailToResend("VENUE_RESERVATION_CONFIRMATION");
              setMenuAnchor(null);
            }}
          >
            Resend {EmailNames["VENUE_RESERVATION_CONFIRMATION"]}
          </MenuItem>
        ) : null}
        {reservation.status === "CANCELLED" ? (
          <MenuItem
            onClick={() => {
              setEmailToResend("VENUE_RESERVATION_CANCELLATION");
              setMenuAnchor(null);
            }}
          >
            Resend {EmailNames["VENUE_RESERVATION_CANCELLATION"]}
          </MenuItem>
        ) : null}
      </Menu>
    );
  }

  function closeDialog() {
    setEmailToResend(null);
  }

  function ResendDialog() {
    if (emailToResend === null) return <></>;

    setMenuAnchor(null);

    return (
      <Dialog open={emailToResend !== null} onClose={closeDialog}>
        <DialogTitle>Resend Email</DialogTitle>
        <DialogContent>
          Are you sure you want to resend the <b>{EmailNames[emailToResend]}</b>{" "}
          email to venue{" "}
          <b>
            {reservation.venue.name} $
            {reservation.venue.nameFurigana
              ? ` (${convertSpaceFullWidthToHalfWidth(
                  reservation.venue.nameFurigana
                )})`
              : ""}
          </b>
          ?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>No</Button>
          <Button color="primary" onClick={() => resendEmail(emailToResend)}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function resendEmail(emailType: EmailType) {
    resendVenueReservationEmail({
      variables: { input: { venueReservationId: reservation.id, emailType } },
    })
      .then(() =>
        setNotification({
          message: `${EmailNames[emailType]} was successfully sent again to ${reservation.venue.email}`,
          severity: "success",
        })
      )
      .catch((reason) => {
        setNotification({
          message: `Resending ${EmailNames[emailType]} failed! ${reason}`,
          severity: "error",
        });
      });

    closeDialog();
  }
}

export default ResendEmailsButton;

const RESEND_VENUE_RESERVATION_EMAIL = gql`
  mutation ResendVenueReservationEmail(
    $input: ResendVenueReservationEmailInput!
  ) {
    resendVenueReservationEmail(input: $input) {
      success
    }
  }
`;

interface ResendEmailsButtonProps {
  reservation: VenueReservationType;
}

const EmailNames: Record<EmailType, string> = {
  VENUE_RESERVATION_REQUEST: "Reservation Request Email",
  VENUE_RESERVATION_CONFIRMATION: "Confirmation Email",
  VENUE_RESERVATION_CANCELLATION: "Cancellation Email",
};

type EmailType =
  | "VENUE_RESERVATION_REQUEST"
  | "VENUE_RESERVATION_CONFIRMATION"
  | "VENUE_RESERVATION_CANCELLATION";
