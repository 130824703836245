import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import Schedule from "../../../../components/Schedule";
import PageContent from "../../../../components/PageContent";
import { Venue } from "../../../../types";
import convertSpaceFullWidthToHalfWidth from "../../../../hooks/useConvertSpaceFullwidthToHalfwidth";

export default function VenueView() {
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery<{ venue: Venue }>(
    VENUE_SCHEDULE_QUERY,
    {
      variables: {
        id,
      },
    }
  );

  if (loading || error || !data || !data.venue) {
    return null;
  }

  const { venue } = data;
  const title = venue.nameFurigana
    ? `${venue.name} (${convertSpaceFullWidthToHalfWidth(venue.nameFurigana)})`
    : venue.name;

  return (
    <PageContent title={title}>
      <Schedule resource={venue} />
    </PageContent>
  );
}

const VENUE_SCHEDULE_QUERY = gql`
  query VenueQuery($id: ID!) {
    venue(id: $id) {
      id
      name
      nameFurigana
      schedule {
        recurrenceRules {
          weekdays
          hours
          minutes
        }
        cutoffTime {
          hours
        }
        bookablePeriod {
          months
        }
        closedDates {
          moment
          locked
        }
        closedTimeSlots
        closedHolidays
        openDates
        openTimeSlots
      }
    }
  }
`;
