import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import queryString from "query-string";

import DataTable from "../../../components/DataTable";
import Button from "../../../components/Button2";
import { IconButton, Tooltip } from "@mui/material";
import UnpublishPlanDialog from "./Dialogs/UnpublishPlan";
import {
  TripleState,
  TripleStateCheckbox,
} from "../components/BasicInfoForm/MultiStateCheckbox/TripleStateCheckbox";
import { PartnerEditNotificationStack } from "./components/PartnerEditNotificationStack";
import { Plan } from "../../../types";
import convertSpaceFullWidthToHalfWidth from "../../../hooks/useConvertSpaceFullwidthToHalfwidth";

export default function PlansList() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 10;
  const [page, setPage] = useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );
  const [listPublishedPlans, setListPublishedPlans] = useState<TripleState>(
    () => {
      const publishedQuery = parsedQueryString.published;
      if (publishedQuery === "true") return true;
      if (publishedQuery === "false") return false;
      return undefined;
    }
  );
  const [listDraftedPlans, setListDraftedPlans] = useState<TripleState>(() => {
    const draftedQuery = parsedQueryString.drafted;
    if (draftedQuery === "true") return true;
    if (draftedQuery === "false") return false;
    return undefined;
  });

  const [listTrashPlans, setListTrashDraftedPlans] = useState<TripleState>(
    () => {
      const trashQuery = parsedQueryString.trash;
      if (trashQuery === "true") return true;
      if (trashQuery === "false") return false;
      return undefined;
    }
  );

  const [
    unpublishingPlan,
    setUnpublishingPlan,
  ] = useState<unpublishPlanData | null>(null);

  const [orderBy, setOrderBy] = useState<string | null>(
    parsedQueryString.orderBy as string
  );

  const [order, setOrder] = useState<"ASC" | "DESC" | null>(
    parsedQueryString.order as "ASC" | "DESC"
  );

  useEffect(updateUrl, [
    page,
    search,
    navigate,
    listDraftedPlans,
    listPublishedPlans,
    listTrashPlans,
  ]);

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_PLANS, {
    variables: {
      offset: page,
      limit: pageSize,
      search: search,
      published: listPublishedPlans,
      drafted: listDraftedPlans,
      trashed: listTrashPlans,
      orderBy: !!orderBy && !!order ? { column: orderBy, order } : undefined,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: dataEdits } = useQuery<GetEditsResponse>(GET_EDITS);

  const rows = data?.plans.records;

  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "", render: renderEditPlan },
    { name: "ID", field: "id" },
    { name: "Name", render: renderPlanName },
    {
      name: "Price Type",
      render: renderPrices,
      sortable: false,
    },
    { name: "Published At", render: renderPublication },
    { name: "Drafts", render: renderDrafts },
  ];

  return (
    <>
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        order={{
          field: orderBy ?? "",
          direction: order ?? "ASC",
          setField: setOrderBy,
          setDirection: setOrder,
        }}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.plans.totalCount : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
        topBarExtras={getTopBarExtras(
          listPublishedPlans,
          setListPublishedPlans,
          listDraftedPlans,
          setListDraftedPlans,
          listTrashPlans,
          setListTrashDraftedPlans,
          dataEdits?.plansWithPendingEdits.plans ?? []
        )}
      />
      <UnpublishPlanDialog
        open={unpublishingPlan !== null}
        onClose={() => setUnpublishingPlan(null)}
        onConfirm={() => {
          refetch();
        }}
        plan={unpublishingPlan}
      />
    </>
  );

  function updateUrl() {
    const urlParams = {
      page: page + 1,
      search: search,
      published: listPublishedPlans,
      drafted: listDraftedPlans,
      trashed: listTrashPlans,
    };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }

  function getTopBarExtras(
    listPublishedPlans: TripleState,
    setListPublishedPlans: (listPublishedPlans: TripleState) => void,
    listDraftedPlans: TripleState,
    setListDraftedPlans: (listDraftedPlans: TripleState) => void,
    listTrashPlans: TripleState,
    setListTrashDraftedPlans: (listTrashPlans: TripleState) => void,
    plansWithEdits: GetEditsResponse["plansWithPendingEdits"]["plans"]
  ) {
    return (
      <div
        style={{
          marginLeft: "0.5rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TripleStateCheckbox
            label="published"
            state={listPublishedPlans}
            setState={(state) => {
              setPage(0);
              setListPublishedPlans(state);
            }}
          />
          <TripleStateCheckbox
            label="drafted"
            state={listDraftedPlans}
            setState={(state) => {
              setPage(0);
              setListDraftedPlans(state);
            }}
          />
          <TripleStateCheckbox
            label="trashed"
            state={listTrashPlans}
            setState={(state) => {
              setPage(0);
              setListTrashDraftedPlans(state);
            }}
          />
        </div>

        <Button
          style={{ backgroundColor: "white", color: "#c8a063" }}
          variant="contained"
          component={Link}
          to="/plans/new"
          startIcon={<AddIcon />}
        >
          New
        </Button>
        <PartnerEditNotificationStack rowData={plansWithEdits} />
      </div>
    );
  }

  function renderEditPlan(plan: { id: string }) {
    return (
      <Button variant="contained" component={Link} to={`/plans/${plan.id}`}>
        <EditIcon />
      </Button>
    );
  }

  function renderPlanName(plan: {
    name: string;
    activities: Array<{ venue: { name: string; nameFurigana: string } }>;
  }) {
    return (
      <>
        {plan.name}
        <br />
        <span style={{ color: "grey" }}>
          {plan.activities
            .map((a) => {
              if (a.venue.nameFurigana)
                return (
                  a.venue.name +
                  `(${convertSpaceFullWidthToHalfWidth(a.venue.nameFurigana)})`
                );
              return a.venue.name;
            })
            .join(",")}
        </span>
      </>
    );
  }

  function renderPublication(plan: {
    id: string;
    name: string;
    publication: { lastPublishedAt: string };
  }) {
    if (!plan.publication) return <></>;

    return (
      <>
        {plan.publication.lastPublishedAt}
        <br />
        <Tooltip title="unpublish">
          <IconButton
            size="small"
            onClick={() => {
              setUnpublishingPlan(plan);
            }}
          >
            <HighlightOffIcon style={{ color: "red" }} fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  function renderDrafts(plan: {
    drafts: { id: string; publishedAt: string }[];
  }) {
    return (
      <>
        {plan.drafts.map((d) => (
          <>
            <a
              style={{ color: "black" }}
              href={`/plans-draft/${d.id}/overview`}
            >
              {d.publishedAt}
            </a>
            <br />
          </>
        ))}
      </>
    );
  }

  function renderPrices(plan: Plan) {
    const { prices } = plan;

    return (
      <div>
        {prices &&
          prices.map((price, idx: number) => (
            <p key={idx}>
              {price.amount.toLocaleString("ja-JP", {
                style: "currency",
                currency: "JPY",
              })}
              <span style={{ fontSize: "12px", marginLeft: "4px" }}>
                {`/${price.unitType}`}
              </span>
            </p>
          ))}
      </div>
    );
  }
}

const GET_EDITS = gql`
  query EditsQuery {
    plansWithPendingEdits {
      error
      plans {
        id
        name
        venueName
      }
    }
  }
`;

type GetEditsResponse = {
  plansWithPendingEdits: {
    error?: string;
    plans: { id: string; name: string; venueName: string }[];
  };
};

const GET_PLANS = gql`
  query PlansListQuery(
    $offset: Int!
    $limit: Int!
    $search: String
    $published: Boolean
    $drafted: Boolean
    $trashed: Boolean
    $orderBy: OrderInput
  ) {
    plans(
      offset: $offset
      limit: $limit
      search: $search
      published: $published
      drafted: $drafted
      orderBy: $orderBy
      trashed: $trashed
    ) {
      records {
        id
        name
        activities {
          venue {
            name
            nameFurigana
          }
        }
        publication {
          lastPublishedAt
        }
        drafts(last: 2) {
          id
          publishedAt
        }
        derivedScore
        prices {
          currencyCode
          unitType
          amount
        }
      }
      totalCount
    }
  }
`;

interface unpublishPlanData {
  id: string;
  name: string;
}
