import { useEffect, useMemo, useState } from "react";
import { gql, useMutation, useQuery, ApolloQueryResult } from "@apollo/client";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";

import TextField from "../../../components/TextField";
import { BookingStatusColor, PaymentStatusColor } from "../helpers";
import CancelBookingButton, {
  getCancelFee,
} from "./ActionButtons/CancelBookingButton";
import RefundPaymentButton from "./ActionButtons/RefundPaymentButton";
import ChangeDateTimeButton from "./ActionButtons/ChangeDateTimeButton";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button2";
import { useNotifications } from "../../../components/Notification";
import {
  Activity,
  Booking,
  BookingStatus,
  EditStatus,
  PaymentStatus,
  ReservationStatus,
} from "../../../types";
import { GET_BOOKING_ACTIVITY_RESERVATIONS } from "./ActivityReservations";
import { ConfirmationSelect } from "./ConfirmationSelect";
import { CircularProgress } from "@mui/material";
import ChangePlanButton from "./ActionButtons/ChangePlanButton";
import { useViewBookingContext } from ".";
import { ItemType } from "./ActivityReservations/Items/Item";
import ListRefundButton from "./ActionButtons/ListRefundButton";

const PaymentMethods = ["STRIPE", "AMAZON_PAY", "ON_SITE_PAYMENT"];

export type DataEditItem = {
  fieldName: string;
  oldValue: string;
  newValue: string;
  editStatus: string;
};

type fieldItem = {
  field: keyof Booking;
  value: any;
};

export default function Basic(props: {
  booking: Booking;
  refetchBooking: () => Promise<ApolloQueryResult<any>>;
}) {
  const {
    newVenue,
    newPlan,
    activityId,
    seatOption,
    priceTypes,
    bookingPrice,
    setBookingPrice,
    clearData,
  } = useViewBookingContext();
  const booking = props.booking;
  const noteLength = 1000;

  const { loading, error, data } = useQuery(reservationsQuery, {
    variables: { bookingId: booking.id },
  });

  const { showNotification } = useNotifications();
  const [status, setStatus] = useState<BookingStatus>(booking.status);
  useEffect(() => setStatus(props.booking.status), [props.booking.status]);

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    booking.paymentStatus
  );
  const [isEditPayment, setIsEditPayment] = useState<boolean>(false);

  const [isEditCustomer, setIsEditCustomer] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(booking?.email || "");
  const [familyName, setFamilyName] = useState<string>(
    booking?.familyName || ""
  );
  const [givenName, setGivenName] = useState<string>(booking?.givenName || "");
  const [familyNameFurigana, setFamilyNameFurigana] = useState<string>(
    booking?.familyNameFurigana || ""
  );
  const [givenNameFurigana, setGivenNameFurigana] = useState<string>(
    booking?.givenNameFurigana || ""
  );

  const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
  const [noShow, setNoShow] = useState(booking.noShow);
  const [notes, setNotes] = useState<string>(booking?.notes || "");
  const [cancellationReason, setCancellationReason] = useState<string>(
    booking?.cancellationReason || "unknown"
  );
  const [cancellationFeeNotes, setCancellationFeeNotes] = useState<string>(
    booking?.cancellationNotes || "unknown"
  );
  const [cancellationFee, setCancellationFee] = useState<number>(
    booking?.cancellationFee ??
      getCancelFee(
        booking.planSnapshot.cancelFee,
        Number(booking.paymentAmount)
      ) ??
      0
  );
  const cancelledBy = useMemo(() => {
    return booking?.cancelledBy || "operator";
  }, [booking]);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    booking?.phoneNumber || ""
  );
  const [charactersLeft, setCharactersLeft] = useState<number>(
    noteLength - notes?.length
  );
  const [editBooking, { loading: saving }] = useMutation(
    EDIT_BOOKING_MUTATION,
    {
      async update() {
        await props.refetchBooking();
      },
    }
  );
  const [saveBookingEdit, { loading: savingBookingEdit }] = useMutation(
    SAVE_BOOKING_EDIT_MUTATION
  );

  if (loading) return <>Loading...</>;
  if (error) return <>Something went wrong</>;
  const activityReservations = data.bookingActivityReservations.records;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Booking Info"
            action={
              ([
                ReservationStatus.NOT_YET_RESERVED,
                ReservationStatus.RESERVED,
              ].includes(activityReservations[0]?.status) ||
                [BookingStatus.CONFIRMED, BookingStatus.CONFIRMED].includes(
                  booking.status
                )) && (
                <CancelBookingButton
                  booking={booking}
                  reservations={activityReservations}
                />
              )
            }
          />
          <Divider />
          <CardContent>
            <TextField readOnly={true} label={"ID"} value={booking.id} />
            <ConfirmationSelect
              label="Status"
              value={status}
              setValue={setStatus}
              values={Object.values(BookingStatus)}
              fontColor={BookingStatusColor[status]}
            />

            <TextField
              readOnly={true}
              label={"Created At"}
              value={booking.createdAt}
            />
            {(status === BookingStatus.CONFIRMED || booking.confirmedAt) && (
              <TextField
                readOnly={true}
                label={"Confirmed At"}
                value={booking.confirmedAt || "determined on save"}
              />
            )}
            {status === BookingStatus.REJECTED && (
              <TextField
                readOnly={true}
                label={"Rejected At"}
                value={booking.rejectedAt || "determined on save"}
              />
            )}
            {status === BookingStatus.CANCELLED && (
              <>
                <TextField
                  readOnly={true}
                  label={"Cancelled At"}
                  value={booking.cancelledAt || "determined on save"}
                />
                <TextField
                  readOnly={true}
                  label={"Cancelled By"}
                  value={booking.cancelledBy || "operator"}
                />
                <TextField
                  label={"Cancellation Reason"}
                  value={cancellationReason}
                  onChange={(e: any) => setCancellationReason(e.target.value)}
                />
                <TextField
                  label={"Cancellation Fee Notes"}
                  value={cancellationFeeNotes}
                  onChange={(e: any) => setCancellationFeeNotes(e.target.value)}
                />
                <TextField
                  disabled={Boolean(booking.planSnapshot.cancelFee)}
                  label={"Cancellation Fee"}
                  value={cancellationFee}
                  onChange={(e: any) => setCancellationFee(e.target.value)}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </>
            )}
            <Checkbox
              style={{ marginTop: "10px" }}
              label="No Show"
              labelPlacement="end"
              checked={noShow}
              onChange={(checked) => setNoShow(checked)}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader
            title="Plan"
            action={
              <>
                {(booking.status === BookingStatus.CONFIRMED ||
                  booking.status === BookingStatus.REQUESTED) && (
                  <ChangeDateTimeButton
                    bookingId={booking.id}
                    currentReservationDateTime={booking.reservationDatetime}
                  />
                )}
                <ChangePlanButton
                  bookingId={booking.id}
                  oldPlan={booking.plan.id}
                />
              </>
            }
          />
          <Divider />
          <CardContent>
            <TextField
              readOnly={true}
              label={"Plan Id"}
              value={newPlan?.id ? newPlan.id : booking.plan.id}
            />
            <TextField
              readOnly={true}
              label={"Plan Name"}
              value={newPlan?.name ? newPlan.name : booking.planSnapshot.name}
            />
            <TextField
              readOnly={true}
              label={"Course Name"}
              value={
                newPlan?.activityName
                  ? newPlan?.activityName
                  : getActivityName(
                      activityReservations[0]?.activityId,
                      booking.planSnapshot.activities
                    )
              }
            />
            <TextField
              readOnly={true}
              label={"Reservation DateTime"}
              value={booking.reservationDatetime}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader title="Internal Notes" />
          <Divider />
          <CardContent>
            <TextField
              label={"Note"}
              multiline
              value={notes}
              onChange={(e: any) => {
                setCharactersLeft(noteLength - e.target.value.length);
                setNotes(e.target.value);
              }}
            />
            <small>{charactersLeft} characters left.</small>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Customer"
            action={
              <Button
                onClick={() => {
                  if (!isEditCustomer) return setIsEditCustomer(true);
                  setEmail(booking.email);
                  setPhoneNumber(booking.phoneNumber);
                  setFamilyName(booking.familyName);
                  setGivenName(booking.givenName);
                  setFamilyNameFurigana(booking.familyNameFurigana);
                  setGivenNameFurigana(booking.givenNameFurigana);
                  setIsEditCustomer(false);
                }}
                style={{ marginLeft: 10 }}
                color="secondary"
              >
                {isEditCustomer && "Cancel"} Change Customer Information
              </Button>
            }
          />
          <Divider />
          <CardContent>
            <TextField
              label={"Email"}
              value={email}
              readOnly={!isEditCustomer}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  readOnly={!isEditCustomer}
                  label={"Family Name"}
                  value={familyName}
                  onChange={(e: any) => {
                    setFamilyName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  readOnly={!isEditCustomer}
                  label={"Given Name"}
                  value={givenName}
                  onChange={(e: any) => {
                    setGivenName(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  readOnly={!isEditCustomer}
                  label={"Family Name Furigana"}
                  value={familyNameFurigana}
                  onChange={(e: any) => {
                    setFamilyNameFurigana(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  readOnly={!isEditCustomer}
                  label={"Given Name Furigana"}
                  value={givenNameFurigana}
                  onChange={(e: any) => {
                    setGivenNameFurigana(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              label={"Phone Number"}
              value={phoneNumber}
              readOnly={!isEditCustomer}
              onChange={(e: any) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </CardContent>
        </Card>
        <Card style={{ marginTop: "24px" }}>
          <CardHeader
            title="Payment"
            action={
              <div>
                {booking.paymentStatus === PaymentStatus.PAID &&
                  booking.paymentIntent?.method !== "ON_SITE_PAYMENT" && (
                    <>
                      <RefundPaymentButton
                        booking={booking}
                        reservations={activityReservations}
                      />
                      <ListRefundButton bookingId={booking.id} />
                    </>
                  )}
                <Button
                  onClick={() => {
                    if (!isEditPayment) return setIsEditPayment(true);
                    setIsEditPayment(false);
                    setBookingPrice(null);
                  }}
                  style={{ marginLeft: 10 }}
                  color="secondary"
                >
                  {isEditPayment && "Cancel"} Change Payment
                </Button>
              </div>
            }
          />
          <Divider />
          <CardContent>
            <>
              <ConfirmationSelect
                label="Payment Status"
                value={paymentStatus}
                setValue={setPaymentStatus}
                values={Object.values(PaymentStatus)}
                fontColor={PaymentStatusColor[paymentStatus]}
                readOnly={!isEditPayment}
              />
              <TextField
                readOnly={true}
                label={"Paid At"}
                value={booking.paidAt || ""}
              />
            </>

            {booking.rejectedAt && (
              <TextField
                readOnly={true}
                label={"Refunded At"}
                value={booking.refundedAt}
              />
            )}
            <TextField
              readOnly={!isEditPayment}
              label={"Payment Amount"}
              value={bookingPrice ?? booking?.paymentAmount}
              onChange={(e: any) => {
                setBookingPrice(e.target.value);
              }}
            />
            <TextField
              readOnly={true}
              label={"Payment Currency"}
              value={booking.paymentCurrency}
            />
            <TextField
              readOnly={true}
              label={"Payment Method"}
              value={booking.paymentIntent?.method}
            />
            <TextField
              readOnly={true}
              label={"Payment Intent Id"}
              value={booking.paymentIntentId || ""}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="Venue" />
          <Divider />
          <CardContent>
            <TextField
              label={"Name"}
              value={newVenue?.name ?? booking.plan.mainVenue?.name}
              readOnly
            />
            <TextField
              label={"Postcode"}
              value={newVenue?.postcode ?? booking.plan.mainVenue?.postcode}
              readOnly
            />
            <TextField
              label={"Address"}
              value={
                newVenue?.location?.address ??
                booking.plan.mainVenue?.location?.address
              }
              readOnly
            />
            <TextField
              label={"Phone Number"}
              value={
                newVenue?.phoneNumber ?? booking.plan.mainVenue?.phoneNumber
              }
              readOnly
            />
            <TextField
              label={"Email Address"}
              value={newVenue?.email ?? booking.plan.mainVenue?.email}
              readOnly
            />
            <TextField
              multiline
              label={"Notification Email Address(es)"}
              value={
                newVenue?.notificationEmails ??
                booking.plan.mainVenue?.notificationEmails
              }
              readOnly
            />
            <TextField
              multiline
              label={"Internal Remarks"}
              value={
                newVenue?.internalRemarks ??
                booking.plan.mainVenue?.internalRemarks
              }
              readOnly
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: "10px" }}>
          <Button
            disabled={saving}
            onClick={() => {
              if (
                bookingPrice &&
                Number(booking.paymentAmount) !== bookingPrice
              ) {
                return setOpenDialogConfirm(true);
              }
              hanldeSaveBookingEdit();
              handleEditBooking();
            }}
          >
            {saving || savingBookingEdit ? (
              <CircularProgress size={20} />
            ) : (
              "Save"
            )}
          </Button>
        </Card>
      </Grid>
      <Dialog open={openDialogConfirm}>
        <DialogTitle>{`Edit Booking?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The booking amount has changed because you have changed your booking
            plan. <br />
            Please confirm the new booking amount.{" "}
            <span
              style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
            >
              {bookingPrice}円
            </span>
            <br />
            If the amount is incorrect please enter a new amount.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleEditBooking();
              hanldeSaveBookingEdit();
              setOpenDialogConfirm(false);
            }}
            style={{ fontWeight: 100 }}
          >
            Continue
          </Button>
          <Button
            style={{ fontWeight: 700 }}
            onClick={() => setOpenDialogConfirm(false)}
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );

  async function hanldeSaveBookingEdit() {
    const bookingNotes = booking.notes ?? "";
    const reservation = activityReservations[0];
    const fields: fieldItem[] = [
      { field: "email", value: email },
      { field: "familyName", value: familyName },
      { field: "familyNameFurigana", value: familyNameFurigana },
      { field: "givenName", value: givenName },
      { field: "givenNameFurigana", value: givenNameFurigana },
      { field: "phoneNumber", value: phoneNumber },
      { field: "paymentStatus", value: paymentStatus },
      { field: "status", value: status },
      { field: "noShow", value: noShow },
    ];

    if (status === BookingStatus.CANCELLED) {
      fields.push({ field: "cancellationReason", value: cancellationReason });
      fields.push({ field: "cancellationFee", value: cancellationFee });
      fields.push({ field: "cancellationNotes", value: cancellationFeeNotes });
    }

    const dataEdits: DataEditItem[] = [];
    fields.forEach((f: fieldItem) => {
      if (f.value !== booking[f.field]) {
        dataEdits.push({
          fieldName: f.field,
          newValue: JSON.stringify({ text: f.value }),
          oldValue: JSON.stringify({ text: booking[f.field] }),
          editStatus: EditStatus.TOUCHED,
        });
      }
    });

    if (bookingNotes !== notes) {
      dataEdits.push({
        fieldName: "Notes",
        newValue: JSON.stringify({ text: notes }),
        oldValue: JSON.stringify({ text: bookingNotes }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    if (
      bookingPrice &&
      Number(booking.paymentAmount) !== Number(bookingPrice)
    ) {
      dataEdits.push({
        fieldName: "Payment amount",
        newValue: JSON.stringify({ text: bookingPrice }),
        oldValue: JSON.stringify({ text: booking.paymentAmount }),
        editStatus: EditStatus.TOUCHED,
      });
    }

    if (newPlan) {
      if (
        booking?.plan?.mainVenue &&
        newPlan?.venue?.id !== booking?.plan?.mainVenue.id
      ) {
        dataEdits.push({
          fieldName: "Venue",
          newValue: JSON.stringify({
            id: newPlan?.venue?.id,
            name: newPlan?.venue?.name,
          }),
          oldValue: JSON.stringify({
            id: booking?.plan?.mainVenue?.id,
            name: booking?.plan?.mainVenue?.name,
          }),
          editStatus: EditStatus.TOUCHED,
        });
      }

      dataEdits.push({
        fieldName: "Plan name",
        newValue: JSON.stringify({ text: newPlan.name }),
        oldValue: JSON.stringify({ text: booking.plan.name }),
        editStatus: EditStatus.TOUCHED,
      });
      dataEdits.push({
        fieldName: "Plan id",
        newValue: JSON.stringify({ text: newPlan.id }),
        oldValue: JSON.stringify({ text: booking.plan.id }),
        editStatus: EditStatus.TOUCHED,
      });
      dataEdits.push({
        fieldName: "Seat option",
        newValue: JSON.stringify({
          id: seatOption?.id,
          title: seatOption?.title,
          price: seatOption?.price,
        }),
        oldValue: JSON.stringify({
          id: reservation.seatOption.id,
          title: reservation.seatOption.title,
          price: reservation.seatOption.price,
        }),
        editStatus: EditStatus.TOUCHED,
      });
      reservation.items.forEach((item: ItemType) => {
        dataEdits.push({
          fieldName: "Price type",
          newValue: JSON.stringify({
            id: item.id,
            priceType: item.priceType,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          oldValue: JSON.stringify({
            id: item.id,
            priceType: item.priceType,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          editStatus: EditStatus.REMOVED,
        });
      });
      priceTypes?.forEach((item: ItemType) => {
        dataEdits.push({
          fieldName: "Price type",
          newValue: JSON.stringify({
            priceType: item.priceType,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          oldValue: JSON.stringify({
            priceType: item.priceType,
            quantity: item.quantity,
            unitType: item.unitType,
          }),
          editStatus: EditStatus.ADDED,
        });
      });
    }

    await saveBookingEdit({
      variables: { input: { editData: dataEdits, bookingId: booking.id } },
    }).catch((error: any) => {
      console.log(error);
    });
  }

  function handleEditBooking() {
    const japaneseCharsRegExp = /^[ぁ-んァ-ン一-龥]/;
    if (
      !japaneseCharsRegExp.test(familyName) ||
      !japaneseCharsRegExp.test(givenName) ||
      !japaneseCharsRegExp.test(familyNameFurigana) ||
      !japaneseCharsRegExp.test(givenNameFurigana)
    ) {
      showNotification({
        message: "Please enter your name in hiragana, katakana or kanji.",
        severity: "error",
      });
      return;
    }
    if (notes.length > noteLength) {
      showNotification({
        message: `You cannot enter notes longer than ${noteLength} characters.`,
        severity: "error",
      });
      return;
    }
    editBooking({
      variables: {
        bookingId: booking.id,
        noShow,
        notes,
        email,
        phoneNumber,
        familyName,
        givenName,
        familyNameFurigana,
        givenNameFurigana,
        status,
        paymentStatus,
        cancellationFee: Number(cancellationFee),
        cancellationReason,
        cancellationFeeNotes,
        cancelledBy,
        paymentAmount: Number(bookingPrice),
        ...(newPlan
          ? {
              newPlan: newPlan.id,
              activityId,
              seatOption: seatOption?.id,
              priceTypes,
            }
          : {}),
      },
      refetchQueries: ["BookingActivityReservationsQuery"],
    })
      .then(() => {
        clearData();
        setIsEditCustomer(false);
        showNotification({
          message: `Booking was edited`,
          severity: "success",
        });
      })
      .catch((err: any) => {
        showNotification({
          message: `Editing booking failed! ${err}`,
          severity: "error",
        });
      });
  }
}

export function getActivityName(id: string, activities: Activity[]) {
  const activity = activities.find((a: Activity) => a.id === id) ?? null;
  return activity ? activity.name : "";
}

export const SAVE_BOOKING_EDIT_MUTATION = gql`
  mutation SaveBookingEditMutation($input: SaveBookingEditInput!) {
    saveBookingEdit(input: $input) {
      success
      message
    }
  }
`;

const EDIT_BOOKING_MUTATION = gql`
  mutation EditBookingMutation(
    $bookingId: ID!
    $noShow: Boolean!
    $notes: String
    $email: String!
    $phoneNumber: String!
    $familyName: String!
    $givenName: String!
    $familyNameFurigana: String!
    $givenNameFurigana: String!
    $status: BookingStatus
    $paymentStatus: PaymentStatus
    $cancellationReason: String
    $cancellationFeeNotes: String
    $cancellationFee: Int
    $cancelledBy: String
    $newPlan: String
    $activityId: String
    $seatOption: String
    $priceTypes: [ItemType]
    $paymentAmount: Int
  ) {
    editBooking(
      input: {
        bookingId: $bookingId
        noShow: $noShow
        notes: $notes
        email: $email
        phoneNumber: $phoneNumber
        familyName: $familyName
        givenName: $givenName
        familyNameFurigana: $familyNameFurigana
        givenNameFurigana: $givenNameFurigana
        status: $status
        paymentStatus: $paymentStatus
        cancellationReason: $cancellationReason
        cancellationFeeNotes: $cancellationFeeNotes
        cancellationFee: $cancellationFee
        cancelledBy: $cancelledBy
        newPlan: $newPlan
        activityId: $activityId
        seatOption: $seatOption
        priceTypes: $priceTypes
        paymentAmount: $paymentAmount
      }
    ) {
      success
    }
  }
`;

const reservationsQuery = GET_BOOKING_ACTIVITY_RESERVATIONS;
