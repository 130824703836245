import {useEffect, useState} from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import PageContent from "../../../../components/PageContent";
import { Plan } from "../../../../types";
import Overview from "../../Overview/Overview";
import { useNotifications } from "../../../../components/Notification";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishIcon from "@mui/icons-material/Publish";
import { STOREFRONT_URL } from "../../../../app.config";
import PublishPlanDialog from "./PublishDialog";
import { SeatOption } from "../Activities/ActivityForm/SeatOptionsForm";
import { cloneDeep } from "lodash";
import { PriceType } from "../Activities/ActivityForm/PriceTypesForm";

export default function PlanOverview() {
  const { id } = useParams<{ id: string }>();
  const { showNotification } = useNotifications();

  const [publishingPlan, setPublishingPlan] = useState<
    (Plan & { draftId: string }) | null
  >(null);

  const [trashed, setTrashed] = useState<string>('');
  const { data, loading, error, refetch } = useQuery<{ plan: Plan }>(
    PLAN_QUERY,
    {
      variables: {
        id,
      },
      fetchPolicy: "network-only",
    }
  );

  const [createPlanDraft] = useMutation<
    { success: boolean },
    { input: { planId: string } }
  >(CREATE_PLAN_DRAFT);

  const [trashUnTrashPlan] = useMutation<
    { success: boolean },
    { input: { planId: string, trashed: boolean|undefined } }
  >(TRASHED_UN_TRASHED_PLAN);

  useEffect(() => {
    if (data?.plan?.trashed) {
      setTrashed('UnTrashed');
    } else {
      setTrashed('Trashed');
    }
  }, [data?.plan.trashed]);

  if (loading || error || !data || !data.plan) {
    return <PageContent></PageContent>;
  }

  const { plan } = data;
  // create deep clone of plan, to be able to modify it
  let planFiltered: Plan = cloneDeep(plan);

  // filter inactive seat options from the display in overview out
  for (const activity of planFiltered.activities) {
    activity.seatOptions = activity.seatOptions.filter(
      (seatOption: SeatOption) => seatOption.active === true
    );
    activity.priceTypes = activity.priceTypes.filter(
      (priceType: PriceType) => priceType.active === true
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item lg={9}>
        <Overview
          plan={planFiltered}
          publishButton={{
            label: "Create draft",
            severity: "danger",
            onClick: () => {
              createPlanDraft({
                variables: { input: { planId: planFiltered.id } },
              })
                .then(() => {
                  showNotification({
                    severity: "success",
                    message: "Draft was created",
                  });
                  refetch();
                })
                .catch((err) => {
                  console.error(err.message);
                  showNotification({
                    severity: "error",
                    message: err.message.substring(0, 1000),
                  });
                });
            },
          }}
        />
        <PageContent
          buttons={[
            {
              label: trashed,
              severity: "default",
              onClick: () => {
                trashUnTrashPlan({
                  variables: { input: { planId: plan.id, trashed: plan.trashed } },
                })
                  .then(() => {
                    showNotification({
                      severity: "success",
                      message: `${trashed} was success`,
                    });
                    refetch();
                  })
                  .catch((err) => {
                    showNotification({
                      severity: "error",
                      message: err.message.substring(0, 1000),
                    });
                  });
              },
            },
          ]}
        >
        </PageContent>
      </Grid>
      <Grid item lg={3}>
        <Card>
          <CardHeader title="Drafts"></CardHeader>
          <CardContent>
            <List>
              {planFiltered.drafts.map((draft) => (
                <ListItem>
                  <ListItemText>{draft.publishedAt}</ListItemText>
                  <IconButton
                    href={`${STOREFRONT_URL}/draft/${planFiltered.id}?draftId=${draft.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (trashed == 'UnTrashed') {
                        showNotification({
                          message: `The status of this plan has been set to "Trashed". To publish it, remove the trashed status from the OVERVIEW page`,
                          severity: "error",
                        });
                      } else {
                        setPublishingPlan({ ...planFiltered, draftId: draft.id });
                      }
                    }}
                  >
                    <PublishIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
      {publishingPlan && (
        <PublishPlanDialog
          open={true}
          onClose={() => setPublishingPlan(null)}
          onConfirm={() => {
            refetch();
          }}
          plan={publishingPlan}
          draftId={publishingPlan.draftId}
        />
      )}
    </Grid>
  );
}

const PLAN_QUERY = gql`
  query PlanQuery($id: ID!) {
    plan(id: $id) {
      id
      name
      subtitle
      timezone
      areaName
      cancellationPolicyDescription
      trashed
      tags {
        name
        tagCategory {
          name
        }
      }
      description
      remarks
      photos {
        imageUrl
      }
      highlights {
        index
        title
        details
        exclusive
        media {
          src
          type
        }
      }
      activities {
        name
        courseMenu
        detailsForVenue
        venue {
          id
          name
          description
          phoneNumber
          email
          numberOfSeats
          businessHours
          holidays
          smokingAllowance
          acceptedPaymentMethods
          dressCode
          limitedEntryForChildren
          babyChair
          kidChair
          wheelChairAccess
          breastFeedingRoom
          parking
          remarks
          photos {
            imageUrl
          }
          location {
            address
            googleMapsUrl
            googleMapsEmbedUrl
            directions
            nearestStations
          }
        }
        contract {
          commissionRate
        }
        priceTypes {
          name
          unitType
          amount
          contractedPrice
          minAttendees
          maxAttendees
          currencyCode
          active
        }
        seatOptions {
          id
          title
          details
          photos {
            src
          }
          maxAttendees
          minAttendees
          active
          price
        }
      }
      drafts(last: 5) {
        id
        publishedAt
      }
    }
  }
`;

const CREATE_PLAN_DRAFT = gql`
  mutation CreatePlanDraft($input: CreatePlanDraftInput!) {
    createPlanDraft(input: $input) {
      success
    }
  }
`;

const TRASHED_UN_TRASHED_PLAN = gql`
  mutation TrashUnTrashPlan($input: TrashUnTrashPlanInput!) {
    trashUnTrashPlan(input: $input) {
      success
    }
  }
`;
