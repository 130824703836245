import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useQuery, gql } from "@apollo/client";
import Chip from "@mui/material/Chip";

import "./index.css";
import DataTable from "../../../components/DataTable";
import { VenueReservationStatusColor } from "../helpers";
import ActionButtons from "./ActionButtons";
import { BookingStatusColor } from "../../Bookings/helpers";
import { VenueReservationType } from "./types";
import convertSpaceFullWidthToHalfWidth from "../../../hooks/useConvertSpaceFullwidthToHalfwidth";

export default function VenueReservations() {
  const navigate = useNavigate();
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);

  const pageSize = 5;
  const [page, setPage] = React.useState(
    parsedQueryString.page ? parseInt(parsedQueryString.page.toString()) - 1 : 0
  );
  const [search, setSearch] = React.useState(
    parsedQueryString.search ? parsedQueryString.search.toString() : null
  );

  React.useEffect(() => {
    const urlParams = { page: page + 1, search: search };
    navigate({
      search: queryString.stringify(urlParams, {
        skipNull: true,
        skipEmptyString: true,
        sort: false,
      }),
    });
  }, [page, search, navigate]);

  const { loading, error, data, fetchMore } = useQuery<
    VenueReservationsQueryResult | undefined
  >(GET_VENUE_RESERVATIONS, {
    variables: { offset: page * pageSize, limit: pageSize, search: search },
  });
  if (error) return <>Error! {error.message}</>;

  const columns = [
    { name: "Status", render: renderReservationStatus },
    { name: "Booking", render: renderBooking },
    { name: "Date Time", field: "datetime", width: "74px" },
    { name: "Customer", render: renderCustomer },
    { name: "Items", width: "70px", render: renderItems },
    { name: "Plan", width: "170px", render: renderPlan },
    { name: "Venue", render: renderVenue },
    { name: "Actions", width: "250px", render: renderActions },
  ];

  const rows = data ? data.venueReservations.records : null;

  return (
    <div className="reservations-container">
      <DataTable
        loading={loading}
        columns={columns}
        rows={rows}
        onSearch={(event) => {
          setSearch(event.target.value);
          setPage(0);
        }}
        searchValue={search}
        pagination={{
          totalRows: data ? data.venueReservations.totalCount : null,
          pageSize: pageSize,
          currentPage: page,
          onChangePage: (_, newPage) => {
            setPage(newPage);
            fetchMore({
              variables: {
                offset: newPage * pageSize,
                limit: pageSize,
                search: search,
              },
              updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
            });
          },
        }}
      />
    </div>
  );
}

function renderReservationStatus(reservation: VenueReservationType) {
  return (
    <Chip
      style={{
        backgroundColor: VenueReservationStatusColor[reservation.status],
        color: "#ffffff",
      }}
      label={reservation.status}
    />
  );
}

function renderBooking(reservation: VenueReservationType) {
  return (
    <>
      {reservation.booking.id}
      <br />
      <span style={{ color: BookingStatusColor[reservation.booking.status] }}>
        {reservation.booking.status}
      </span>
    </>
  );
}

function renderCustomer(reservation: VenueReservationType) {
  return (
    <>
      {reservation.booking.familyName} {reservation.booking.givenName}
      <br />
      {reservation.booking.familyNameFurigana}{" "}
      {reservation.booking.givenNameFurigana}
      <br />
      {reservation.booking.phoneNumber}
    </>
  );
}

function renderItems(reservation: VenueReservationType) {
  return (
    <ul style={{ paddingInlineStart: "16px" }}>
      {reservation.items.map(
        (item: { priceType: string; quantity: number }, index) => (
          <li key={index}>
            {item.priceType}: {item.quantity}
          </li>
        )
      )}
    </ul>
  );
}

function renderPlan(reservation: VenueReservationType) {
  return (
    <>
      {reservation.plan.id}
      <br />
      {reservation.plan.name}
    </>
  );
}

function renderVenue(reservation: VenueReservationType) {
  return (
    <>
      {reservation.venue.name}
      {reservation.venue.nameFurigana &&
        ` (${convertSpaceFullWidthToHalfWidth(
          reservation.venue.nameFurigana
        )})`}
      <br />
      {reservation.venue.phoneNumber}
      <br />
      {reservation.venue.email}
    </>
  );
}

function renderActions(reservation: VenueReservationType) {
  return <ActionButtons reservation={reservation} />;
}

const GET_VENUE_RESERVATIONS = gql`
  query VenueReservationsListQuery(
    $offset: Int!
    $limit: Int!
    $search: String
  ) {
    venueReservations(offset: $offset, limit: $limit, search: $search) {
      records {
        id
        status
        datetime
        items {
          priceType
          quantity
          contractedPrice
        }
        booking {
          id
          status
          paymentStatus
          familyName
          givenName
          familyNameFurigana
          givenNameFurigana
          phoneNumber
        }
        venue {
          name
          nameFurigana
          phoneNumber
          email
        }
        activity {
          contract {
            commissionRate
          }
        }
        plan {
          id
          name
          cancellationPolicyDescription
        }
      }
      totalCount
    }
  }
`;

interface VenueReservationsQueryResult {
  venueReservations: {
    totalCount: number;
    records: VenueReservationType[];
  };
}
