import {
  Grid,
  Paper,
  Card,
  CardContent,
  Theme,
  Tabs,
  Tab,
} from "@mui/material";
import { useState, useEffect } from "react";
import QuestionsForm from "./QuestionsForm";
import PriceTypesForm from "./PriceTypesForm";
import SeatOptionsForm from "./SeatOptionsForm";
import ImageGalleryUploader from "../../../../../components/ImageGalleryUploader";
import TextField from "../../../../../components/TextField";
import Checkbox from "../../../../../components/Checkbox";
import { v4 as uuid } from "uuid";
import { makeStyles } from "@mui/styles";
import { UnitType } from "../../../../../types";
import Button from "../../../../../components/Button2";
import DeleteActivityDialog from "./DeleteActivityDialog";

type Activity = {
  id?: string;
  name?: string;
  courseMenu?: string;
  coursePhotos?: {
    activityId: string;
    id: string;
    src: string;
    photoIndex: number;
  }[];
  questionsByVenue?: string[];
  priceTypes?: {
    id: string;
    name?: string;
    unitType?: UnitType;
    amount?: number;
    contractedPrice?: number;
    currencyCode?: string;
    minAttendees?: number;
    maxAttendees?: number;
  }[];
  seatOptions?: {
    id?: string;
    title?: string;
    details?: string;
    minAttendees?: number;
    maxAttendees?: number;
    active?: boolean;
    photos: { src: string }[];
    price?: number;
  }[];
  hideCourseName?: boolean;
};

type ActivityFormProps = {
  activity?: Activity[];
  onChange: (activity: Activity) => void;
  setActive?: (active: any) => void;
  active?: any;
  refetch?: () => void;
  planId?: string;
  isEdit?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    width: "100%",
    overflow: "auto",
  },
  item: {
    minWidth: "max-content",
    padding: "10px 0",
    textAlign: "center",
    border: "1px solid #C8A063",
    cursor: "pointer",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  actionsButton: {
    display: "flex",
    gap: 10,
    marginBottom: 10,
  },
}));

export default function MultipleActivity(props: ActivityFormProps) {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const {
    planId,
    activity,
    onChange,
    refetch,
    setActive = () => {},
    active,
  } = props;
  const [activities, setActivities] = useState<Activity[]>(
    activity as Activity[]
  );
  const [currentActivity, setCurrentActivity] = useState<Activity | any>(
    activities[active]
  );
  const classes = useStyles();

  const handleChange = (value: Activity) => {
    setCurrentActivity({
      ...currentActivity,
      ...value,
    });
  };

  useEffect(() => {
    onChange(currentActivity);

    const updatedActivities = activities.map((item: any, index: number) =>
      index === active ? currentActivity : item
    );

    setActivities(updatedActivities);
  }, [currentActivity]);

  useEffect(() => {
    setActivities(props.activity as Activity[]);
  }, [props.activity]);

  useEffect(() => {
    setCurrentActivity(activities[active]);
  }, [active]);

  const handleAddActivity = () => {
    setActivities((prev) => [
      ...prev,
      {
        id: "",
        name: "",
        courseMenu: "",
        coursePhotos: [],
        questionsByVenue: [],
        priceTypes: [],
        seatOptions: [],
        deleteId: uuid(),
        hideCourseName: false,
      },
    ]);
    setActive(activities.length ?? 0);
  };

  const handleCopyActivity = () => {
    setActivities((prev) => [
      ...prev,
      {
        ...currentActivity,
        priceTypes: currentActivity.priceTypes.map((priceType: any) => {
          return { ...priceType, id: uuid() };
        }),
        seatOptions: currentActivity.seatOptions.map((option: any) => {
          return { ...option, id: uuid() };
        }),
        coursePhotos: currentActivity.coursePhotos.map((photo: any) => {
          return { ...photo, id: uuid() };
        }),
        questionsByVenue: currentActivity.questionsByVenue.map(
          (question: any) => {
            return question;
          }
        ),
        id: "",
        deleteId: uuid(),
      },
    ]);
    setActive(activities.length);
  };

  const hanldeDeleteActivity = () => {
    if (!currentActivity?.deleteId) {
      if (setIsDeleting) setIsDeleting(true);
    } else {
      setActivities((prev: any) => {
        return prev.filter(
          (a: any) => a.deleteId !== currentActivity?.deleteId
        );
      });
      setActive((prev: any) => (prev == 0 ? 0 : prev - 1));
    }
  };

  return (
    <Grid lg={12} style={{ backgroundColor: "#fff", marginLeft: "8px" }}>
      <DeleteActivityDialog
        activity={currentActivity}
        open={isDeleting}
        onConfirm={() => {
          setIsDeleting(false);
          setActive((prev: any) => (active == 0 ? 1 : prev - 1));
          if (refetch) refetch();
        }}
        onClose={() => {
          setIsDeleting(false);
        }}
      />
      <div className={classes.header}>
        <Paper style={{ marginBottom: "10px" }}>
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: "#c8a063" } }}
            value={active}
            onChange={(_event: any, newValue: number) => setActive(newValue)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {activities.map((item: any, index: number) => (
              <Tab
                style={
                  index === active
                    ? { backgroundColor: "#c8a063", color: "white" }
                    : {}
                }
                key={index}
                label={item?.name ? item?.name : `activity ${index + 1}`}
              />
            ))}
          </Tabs>
        </Paper>
        <Tab onClick={handleAddActivity} label="+ activity" />
      </div>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <div className={classes.box}>
            <Card>
              <CardContent>
                <Checkbox
                  label="Hide Course Name"
                  labelPlacement="end"
                  checked={!!currentActivity?.hideCourseName}
                  onChange={(checked) =>
                    handleChange({ hideCourseName: checked })
                  }
                />
                <TextField
                  label="Course Name"
                  value={currentActivity?.name}
                  onChange={(e: any) => {
                    handleChange({ name: e.target.value });
                  }}
                />
                <TextField
                  multiline
                  label="Course menu"
                  value={currentActivity?.courseMenu || ""}
                  onChange={(e: any) => {
                    handleChange({ courseMenu: e.target.value });
                  }}
                />
                <div style={{ marginBottom: 10, marginTop: 20 }}>
                  <strong style={{ marginTop: 10 }}>Course Photos</strong>
                  <Paper
                    elevation={0}
                    style={{
                      width: "auto",
                      height: "auto",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <ImageGalleryUploader
                      isChangeable={true}
                      images={
                        currentActivity?.coursePhotos
                          ? currentActivity.coursePhotos.map((cp: any) => ({
                              id: cp.id,
                              imageUrl: cp.src,
                              photoIndex: cp.photoIndex,
                            }))
                          : []
                      }
                      uploadPath={`plans/${planId}/activity/${currentActivity?.id}/course`}
                      gridSizes={{ xs: 12, md: 4 }}
                      onChangeFunction={(coursePhotos: any) => {
                        handleChange({
                          coursePhotos: coursePhotos?.map(
                            (p: any, index: number) => {
                              return {
                                activityId: currentActivity?.id,
                                id: p.id ? p.id : uuid(),
                                photoIndex: index,
                                src: p?.imageUrl,
                              };
                            }
                          ),
                        });
                      }}
                    />
                  </Paper>
                </div>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item lg={6}>
          <div className={classes.box}>
            <div className={classes.actionsButton}>
              <Button
                onClick={hanldeDeleteActivity}
                disabled={activities.length === 1}
              >
                Delete Course
              </Button>
              <Button onClick={handleCopyActivity}>Copy Course</Button>
            </div>
            <PriceTypesForm
              activity={currentActivity}
              onChange={handleChange}
            />
          </div>
          <div className={classes.box}>
            <QuestionsForm activity={currentActivity} onChange={handleChange} />
          </div>
        </Grid>
        <Grid item lg={12}>
          <SeatOptionsForm
            activity={currentActivity}
            planId={planId ?? ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
